import { api_key } from "src/config/config";
import { invokeApi } from "../../bl_libs/invokeApi";

export const websitePagesContent = async (data) => {
  const requestObj = {
    path: `app/get_website_page`,
    method: "POST",
    headers: {
      "x-auth-api-key": api_key,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const websitePagesList = async (id) => {
  const requestObj = {
    path: `api/page_group/list_shareable_group_to_client/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
